import { useEffect, useMemo, useState } from "react";

import { Col, Row, theme, Typography } from "antd";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch } from "react-redux";

import {
  useGetCurrentUserQuery,
  useGetLanguagesQuery, useGetOrganizationsQuery,
} from "../../redux/service.js";
import { ObjectTypes, Roles } from "../../utilities/constants.jsx";
import PrimaryButton from "../../components/PrimaryButton.jsx";
import ObjectModalForm from "./ObjectModalForm.jsx";
import ObjectOverviewModal from "./ObjectOverviewModal.jsx";
import ObjectsTable from "./components/ObjectsTable.jsx";
import { setShowGreyBackground } from "../../redux/utils/reducer.js";
import TrendingObjects from "./components/TrendingObjects.jsx";
import Select from "../../components/form/Select.jsx";

const { Title } = Typography;

const ObjectsList = () => {
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { data: languages, isSuccess: isLanguagesSuccess } =
    useGetLanguagesQuery();
  const { data: organizationsData, isSuccess: isOrganizationsSuccess} = useGetOrganizationsQuery({ pagination: "off" });
  const { data: currentUser, isSuccess: isCurrentUserSuccess } =
    useGetCurrentUserQuery();
  const [createObjectModal, setCreateObjectModal] = useState(false);
  const [objectDetailsModal, setObjectDetailsModal] = useState({
    open: false,
    object: null,
  });
  const [selectedTab, setSelectedTab] = useState(null);

  const form = useForm({
    mode: "onChange",
  });
  const filters = useWatch({ control: form.control });

  const inactiveTabStyles = {
    color: "rgba(0, 7, 19, 0.624)",
    borderBottom: "3px solid rgba(0, 7, 19, 0.2)",
    fontWeight: 500,
    cursor: "pointer",
  };
  const activeTabStyles = {
    background: token.backgroundColor,
    cursor: "pointer",
    color: "white",
    fontWeight: 600,
    borderBottom: "1px solid #4A699C",
  };

  useEffect(() => {
    if (isCurrentUserSuccess) {
      setSelectedTab(
        currentUser.role === Roles.ADMIN.value ? "explore" : "private",
      );
    }
  }, [isCurrentUserSuccess]);

  useEffect(() => {
    dispatch(setShowGreyBackground(true));

    return () => dispatch(setShowGreyBackground(false));
  }, []);

  const columns = [
    {
      title: t("table.title"),
      dataIndex: "title",
      key: "title",
      ellipsis: true,
      addSearch: true,
      width: 400,
      render: (_, record) => (
        <a
          style={{ color: token.linkColor, textDecoration: "underline" }}
          onClick={() => setObjectDetailsModal({ open: true, object: record })}
        >
          {record.title}
        </a>
      ),
    },
    {
      title: t("table.language"),
      dataIndex: "language",
      ellipsis: true,
      addSearch: true,
      filters:
        isLanguagesSuccess &&
        languages.map((i) => ({
          text: i.label,
          value: i.value,
        })),
      key: "language",
      render: (_, record) =>
        isLanguagesSuccess &&
        languages.find((i) => i.value === record.language).label,
    },
    {
      title: t("table.type"),
      dataIndex: "type",
      key: "type",
      render: (_, record) => ObjectTypes.getItemByValue(record.type)?.label,
      addSearch: true,
      filters: ObjectTypes.asList().map((i) => ({
        text: i.label,
        value: i.value,
      })),
    },
    {
      title: t("table.createdBy"),
      dataIndex: "createdBy",
      key: "createdBy",
      render: (_, record) => record?.createdBy?.fullName,
    },
    {
      title: t("table.lastModified"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (_, record) => DateTime.fromISO(record.updatedAt).toRelative(),
    },
  ];

  const objectFilters = useMemo(() => {
    if (isCurrentUserSuccess) {
      return {
        ...filters,
        organizations:
          selectedTab === "private" && currentUser.role !== Roles.ADMIN.value
            ? currentUser.organization.id
            : filters.organization?.value,
        exclude_organizations:
          selectedTab === "explore" && currentUser.role === Roles.USER.value
            ? currentUser.organization.id
            : null,
      };
    }
    return null;
  }, [selectedTab, filters, isCurrentUserSuccess]);

  const getStylesForTab = (tab) => {
    return selectedTab === tab ? activeTabStyles : inactiveTabStyles;
  };

  return (
    isCurrentUserSuccess &&
    selectedTab && (
      <div>
        <Row className="mb-4">
          <Col sm={12} md={5} lg={5}>
            <Title
              level={3}
              style={{ fontWeight: 700, color: token.colorPrimaryText }}
            >
              {t("objectRepository")}
            </Title>
          </Col>
          <Col sm={12} md={5} lg={5}>
            {currentUser.role === Roles.USER.value && (
              <div style={{ display: "flex" }}>
                <div
                  className="py-2 px-3"
                  style={getStylesForTab("private")}
                  onClick={() => setSelectedTab("private")}
                >
                  <span>{t("yourObjects")}</span>
                </div>
                <div
                  className="py-2 px-3"
                  style={getStylesForTab("explore")}
                  onClick={() => setSelectedTab("explore")}
                >
                  <span>{t("exploreObjects")}</span>
                </div>
              </div>
            )}
          </Col>
          <Col sm={24} md={14} lg={14} style={{ textAlign: "right" }}>
            {currentUser?.role === Roles.USER.value && (
              <PrimaryButton
                style={{ fontSize: "14px" }}
                type="secondary"
                onClick={() => setCreateObjectModal(true)}
              >
                <img
                  src="/assets/add-new-icon.svg"
                  style={{ verticalAlign: "baseline" }}
                  alt="Add new icon"
                  className="me-2"
                />
                {t("createNewObject")}
              </PrimaryButton>
            )}
          </Col>
        </Row>
        {selectedTab !== "private" && <TrendingObjects />}
        <div className="mt-4 mb-3 pt-3">
          {selectedTab === "explore" && (
            <Title
              level={4}
              className="mb-3"
              style={{ color: token.colorBackgroundSecondary }}
            >
              {t("allObjects")}
            </Title>
          )}
          <Row gutter={10} className="mb-2">
            {selectedTab !== "private" && (
              <Col sm={24} md={4} lg={4}>
                <Select
                  name="organizations"
                  placeholder={t("form.placeholders.selectInstitutions", {
                    plural: "s",
                  })}
                  options={isOrganizationsSuccess ? organizationsData.map((i) => ({ label: i.name, value: i.id })) : []}
                  control={form.control}
                />
              </Col>
            )}
            <Col sm={24} md={2} lg={2}>
              <a onClick={() => form.reset()}>{t("clearFilters")}</a>
            </Col>
          </Row>
          <ObjectsTable columns={columns} filters={objectFilters} />
        </div>
        <ObjectModalForm
          open={createObjectModal}
          onCancel={() => setCreateObjectModal(false)}
        />
        <ObjectOverviewModal
          open={objectDetailsModal.open}
          object={objectDetailsModal.object}
          onCancel={() => setObjectDetailsModal({ open: false, object: null })}
        />
      </div>
    )
  );
};

export default ObjectsList;
