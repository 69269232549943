import {useEffect} from "react";

import {DownloadOutlined} from "@ant-design/icons";
import {theme} from "antd";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

import dayjs from "dayjs";

import {
    useGetCountriesQuery,
    useLazyPaginateFilterSurveysQuery, useLazyPaginateFilterSurveyViewsQuery,
} from "../../../../redux/service.js";
import Table from "../../../../components/Table.jsx";
import DownloadSurvey from "../DownloadSurvey.jsx";
import TrendingSurveys from "./TrendingSurveys.jsx";


const SignatorySurveys = () => {
    const { token } = theme.useToken();
    const { t } = useTranslation();
      const navigate = useNavigate();

    const { data: countries, isSuccess: isCountriesSuccess } =
    useGetCountriesQuery();

    const [getSurveys, surveysResponse] = useLazyPaginateFilterSurveysQuery();
    const [paginateSurveyViews, { data: surveyViews, isSuccess: isSurveyViewsSuccess, isLoading: isSurveyViewsLoading }] =
    useLazyPaginateFilterSurveyViewsQuery();

    useEffect(() => {
        paginateSurveyViews({
          pageSize: 10,
          isDefault: true,
        })
    }, []);

    useEffect(() => {
        if (isSurveyViewsSuccess) {
            const surveyIds = surveyViews.results.map((i) => i.survey);
            getSurveys({ids: surveyIds, pagination: 'off'});
        }
    }, [isSurveyViewsSuccess]);


      const columns = [
    {
      title: t("table.organizationName"),
      dataIndex: "organizationName",
      key: "organizationName",
      addSearch: true,
      ellipsis: true,
      render: (_, record) => {
        return (
          <a
            style={{ color: token.linkColor, textDecoration: "underline" }}
            onClick={() => navigate(`/organizations/${record.organization.id}`)}
          >
            {record.organization.name}
          </a>
        );
      },
    },
    {
      title: t("table.year"),
      dataIndex: "survey.year",
      key: "surveyYear",
        width: 80,
      addSearch: true,
      render: (_, record) => {
          if (surveysResponse.isSuccess) {
              const survey = surveysResponse.data.find((i) => i.id === record.survey);
              return dayjs(survey.startDate).format("YYYY");
          }
      },
    },
    {
      title: t("form.labels.country"),
      dataIndex: "countries",
      key: "countries",
        width: 180,
      addSearch: true,
     filters:
        isCountriesSuccess &&
        countries.map((i) => ({
          text: i.label,
          value: i.value,
        })),
      render: (_, record) =>
        isCountriesSuccess &&
        countries.find((i) => i.value === record.organization.country)?.label,
    },
    {
      title: t("table.dateSubmitted"),
      dataIndex: "survey.submittedAt",
      key: "submittedAt",
        width: 190,
      render: (_, record) =>
        dayjs(record.surveyDetails.submittedAt).format("DD MMMM YYYY"),
    },
    {
      title: t("table.admin"),
      dataIndex: "organization.admin.fullName",
      key: "admin",
        width: 270,
      render: (_, record) => (
          <a style={{ color: token.linkColor, textDecoration: "underline" }} onClick={() => navigate(`/profile/${record.organization.admin.id}`)}>
              {record.organization.admin?.fullName}
          </a>
      )
    },
    {
      title: t("table.view"),
      dataIndex: "survey.view",
      key: "view",
        width: 70,
      render: (_, record) => {
        return (
          <a
            style={{ color: token.linkColor, textDecoration: "underline" }}
            onClick={() => navigate(`/reports/${record.survey}/report?view=${record.uuid}`)}
          >
            <img src="/assets/new-tab.svg" style={{ width: '20px' }} alt="new tab icon" />
          </a>
        );
      },
    },
    {
      title: t("table.download"),
      dataIndex: "survey.download",
      key: "download",
        width: 110,
      render: (_, record) => {
      if (surveysResponse.isSuccess) {
          const survey = surveysResponse.data.find((i) => i.id === record.survey);
          return (
            <div>
                <DownloadSurvey
                    survey={survey}
                  >
             <DownloadOutlined style={{ color: "grey", fontSize: "20px"}} />
         </DownloadSurvey>
            </div>
        );
      }
      },
    },
  ];

    const handleTableChange = (pagination, filterObj) => {
        paginateSurveyViews({
      page: pagination.current,
      pageSize: pagination.pageSize,
      survey_year: filterObj?.surveyYear,
      countries: filterObj?.countries,
      organization_name: filterObj?.organizationName,
        isDefault: true,
    })
  };

    return (
        <div className="mt-3">
            <TrendingSurveys />
           {isSurveyViewsSuccess && (
                <div className="mt-3">
                    <Table
                        data={surveyViews.results}
                        totalCount={surveyViews.count}
                        isLoading={isSurveyViewsLoading}
                        columns={columns}
                        handleTableChange={handleTableChange}
                      />
                </div>
            )}
        </div>
    )
};

export default SignatorySurveys;
