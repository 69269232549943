import { useMemo, useState } from "react";

import PropTypes from "prop-types";
import { CloseOutlined, CopyOutlined, EditOutlined } from "@ant-design/icons";
import { App, Tag, theme } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Table from "../../../components/Table.jsx";
import PrimaryButton from "../../../components/PrimaryButton.jsx";
import { OrganizationRoles, Roles } from "../../../utilities/constants.jsx";
import InviteFormModal from "./InviteFormModal.jsx";
import MembershipFormModal from "./MembershipFormModal.jsx";
import { useLazyImpersonateUserQuery } from "../../../redux/service.js";

const Members = ({ organization, currentUser }) => {
  const { notification } = App.useApp();
  const { token } = theme.useToken();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [memberModalDetails, setMemberModalDetails] = useState({
    open: false,
    member: null,
    isDelete: false,
  });
  const [impersonateUser] = useLazyImpersonateUserQuery();
  const invites = organization.invites.map((i) => ({ ...i, isInvite: true }));
  const members = organization.members.map((i) => ({ ...i, isInvite: false }));
  const organizationMembersAndInvites = [...invites, ...members];
  const canUserEdit = useMemo(
    () =>
      currentUser &&
      (currentUser.role === Roles.ADMIN.value ||
        [
          OrganizationRoles.ADMIN.value,
          OrganizationRoles.SUPERVISOR.value,
        ].includes(currentUser.organizationRole)),
    [currentUser],
  );

  const handleEdit = (member) => {
    setMemberModalDetails({ member, open: true, isDelete: false });
  };

  const handleDelete = (member) => {
    setMemberModalDetails({ member, open: true, isDelete: true });
  };

  const openModal = () => {
    setMemberModalDetails({ member: null, open: true, isDelete: false });
  };

  const onCancel = () => {
    setMemberModalDetails({ member: null, open: false, isDelete: false });
  };

  const handleImpersonateUser = (userId) => {
    impersonateUser(userId)
      .unwrap()
      .then(() => {
        navigate("/");
        window.location.reload();
      });
  };

  const columns = [
    {
      title: t("form.labels.firstName"),
      dataIndex: "firstName",
      key: "firstName",
      render: (_, record) =>
        record.isInvite ? (
          <Tag
            className="w-100 p-1"
            style={{
              background: token.colorBackgroundSecondary,
              color: "white",
              fontSize: "14px",
            }}
          >
            {t("tags.invitePending")}
          </Tag>
        ) : (
          record.user.firstName
        ),
    },
    {
      title: t("form.labels.lastName"),
      dataIndex: "lastName",
      key: "lastName",
      render: (_, record) => (record.isInvite ? "" : record.user.lastName),
    },
    {
      title: t("form.labels.email"),
      dataIndex: "email",
      key: "email",
      render: (_, record) =>
        record.isInvite ? record.email : record.user.email,
    },
    {
      title: t("form.labels.role"),
      dataIndex: "role",
      key: "role",
      render: (_, record) => {
        const role = OrganizationRoles.getItemByValue(record.role);
        return <Tag color={role.color}>{role.label}</Tag>;
      },
    },
    ...(currentUser.role === Roles.ADMIN.value
      ? [
          {
            title: t("buttons.registrationLink"),
            key: "registrationLink",
            render: (_, record) =>
              record?.registrationLink && (
                <CopyOutlined
                  onClick={() => {
                    navigator.clipboard.writeText(record.registrationLink);
                    notification.success({
                      message: t("registrationLinkCopied"),
                    });
                  }}
                />
              ),
          },
        ]
      : []),
    ...(currentUser.role === Roles.ADMIN.value && currentUser.isSuperuser
      ? [
          {
            title: t("buttons.impersonate"),
            key: "impersonate",
            render: (_, record) =>
              record?.isInvite ? null : (
                <PrimaryButton
                  onClick={() => handleImpersonateUser(record.user.id)}
                >
                  {t("buttons.impersonate")}
                </PrimaryButton>
              ),
          },
        ]
      : []),
    ...(canUserEdit && currentUser
      ? [
          {
            title: t("buttons.edit"),
            key: "edit",
            render: (_, record) =>
              currentUser.role === Roles.ADMIN.value ||
              record.role !== OrganizationRoles.ADMIN.value ? (
                <EditOutlined onClick={() => handleEdit(record)} />
              ) : null,
          },
          {
            title: t("buttons.remove"),
            key: "remove",
            render: (_, record) =>
              record.isInvite ||
              currentUser.role === Roles.ADMIN.value ||
              (record.user.id !== currentUser.id &&
                record.role !== OrganizationRoles.ADMIN.value) ? (
                <CloseOutlined onClick={() => handleDelete(record)} />
              ) : null,
          },
        ]
      : []),
  ];

  const FormModal = useMemo(() => {
    const props = {
      currentUser,
      onCancel,
      open: memberModalDetails.open,
      isDeleteOperation: memberModalDetails.isDelete,
      organizationId: organization.id,
    };
    if (memberModalDetails.member) {
      if (memberModalDetails.member.isInvite) {
        return (
          <InviteFormModal
            inviteForEdit={memberModalDetails.member}
            {...props}
          />
        );
      } else {
        return (
          <MembershipFormModal member={memberModalDetails.member} {...props} />
        );
      }
    } else {
      return <InviteFormModal {...props} />;
    }
  }, [currentUser, organization.id, memberModalDetails]);

  return (
    <div>
      {canUserEdit && (
        <PrimaryButton
          className="mb-2"
          style={{ float: "right" }}
          onClick={openModal}
        >
          {t("buttons.inviteUser")}
        </PrimaryButton>
      )}
      <Table
        data={organizationMembersAndInvites}
        columns={columns}
        totalCount={organizationMembersAndInvites.length}
      />
      {FormModal}
    </div>
  );
};

Members.propTypes = {
  organization: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default Members;
