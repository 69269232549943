import { useEffect, useRef, useState } from "react";

import { Select, Spin } from "antd";
import { useController } from "react-hook-form";
import PropTypes from "prop-types";
import { debounce } from "lodash";
import {InboxOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";

import Tag from "../Tag.jsx";
import FieldError from "./FieldError.jsx";


const DebouncedSelect = ({
  name,
  trigger,
  filterName,
  labelName,
  valueName,
  control,
  placeholder,
  mode,
  initialSearchValue,
  defaultOpen,
  ...props
}) => {
  const { t } = useTranslation();

  const {
    field,
    fieldState: { error },
  } = useController({ name, control });
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const fetchRef = useRef(0);
  const [searchValue, setSearchValue] = useState(initialSearchValue || null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    return () => {
      setOpen(false);
      setOptions([]);
      setSearchValue(null);
    };
  }, []);

  useEffect(() => {
    if (open) {
      loadOptions();
    } else {
      resetOptionsAndPageOnSearchChange();
      setSearchValue(null);
    }
  }, [open]);

  const resetOptionsAndPageOnSearchChange = () => {
    setPage(1);
    setOptions([]);
  }

  useEffect(() => {
    if (searchValue) {
      const debounced = debounce(loadOptions, 500);
      resetOptionsAndPageOnSearchChange();
      debounced();

      return debounced.cancel;
    }
  }, [searchValue]);

  const loadOptions = () => {
    setFetching(true);

    fetchRef.current += 1;
    const fetchId = fetchRef.current;
    trigger({ [filterName]: searchValue ? encodeURIComponent(searchValue) : null , page })
      .then((response) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions((oldOptions) => {
          const oldOptionsValues = oldOptions.map((i) => i.value);
          return [...oldOptions, ...response.data.results.map((i) => ({ label: i[labelName], value: i[valueName]})).filter((i) => !oldOptionsValues.includes(i[valueName]))];
        });

        setPage(response.data.next);
        setOpen(true);
      })
      .catch(() => setPage(null))
      .finally(() => setFetching(false));
  };

  const handlePopupScroll = (e) => {
    const target = e.target;
    if (page && target.scrollTop + target.offsetHeight >= target.scrollHeight - 360) {
        loadOptions(searchValue, page);
    }
  };

  const LoadingContent = (
      <div style={{textAlign: "center"}}><Spin size="large"/></div>
  );

  const NotFoundContent = (
      <div style={{ textAlign: "center" }}>
        <InboxOutlined style={{ fontSize: '35px' }} />
        <p>{t("noData")}</p>
      </div>
  )

  return (
      <div>
        <Select
            labelInValue
            style={{ width: "100%" }}
        onSearch={(val) => setSearchValue(val)}
        searchValue={searchValue}
        mode={mode}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        tagRender={Tag}
        filterOption={false}
        options={options}
        notFoundContent={fetching ? LoadingContent : NotFoundContent}
        open={open}
        defaultOpen={defaultOpen}
        onDropdownVisibleChange={(open) => setOpen(open)}
        {...field}
        onPopupScroll={handlePopupScroll}
        onChange={(val) => {
          field.onChange(val);
          setSearchValue("");
        }}
        value={field.value || undefined}
        placeholder={placeholder}
        {...props}
      />
      {error && <FieldError errors={error} />}
    </div>
  );
};

DebouncedSelect.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  filterName: PropTypes.string.isRequired,
  labelName: PropTypes.string.isRequired,
  trigger: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  initialSearchValue: PropTypes.string,
  defaultOpen: PropTypes.bool,
  valueName: PropTypes.string,
  mode: PropTypes.string,
};

DebouncedSelect.defaultProps = {
  mode: "single",
  initialSearchValue: null,
  defaultOpen: false,
  valueName: "id"
};

export default DebouncedSelect;
