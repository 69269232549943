import { useMemo, useRef, useState } from "react";

import { App, Col, Divider, Row, Typography } from "antd";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HeartOutlined } from "@ant-design/icons";

import Avatar from "../../../components/Avatar.jsx";
import { Roles } from "../../../utilities/constants.jsx";
import Tools from "../../../components/Tools.jsx";
import {
  useDeleteCommentMutation,
  useGetCurrentUserQuery,
  useLikeCommentMutation,
  useLazyReportCommentQuery,
  usePatchCommentMutation,
} from "../../../redux/service.js";
import CommentForm from "./CommentForm.jsx";
import PrimaryButton from "../../../components/PrimaryButton.jsx";
import Comments from "./Comments.jsx";
import QuillInput from "../../../components/form/QuillInput.jsx";

const { Title, Text } = Typography;

const AttachedEntity = ({ entity, onClick }) => {
  const { t } = useTranslation();

  return (
    <Row
      key={entity.id}
      className="py-2 px-3 mb-2 w-75"
      style={{
        backgroundColor: "rgba(230, 237, 254, 1)",
        display: "flex",
      }}
    >
      <Col lg={21} md={19} xs={24}>
        <Title
          level={5}
          className="me-3"
          style={{ color: "rgba(74, 105, 156, 1)" }}
        >
          {entity.title}
        </Title>
      </Col>
      <Col lg={3} md={6} xs={24}>
        <PrimaryButton onClick={onClick}>{t("buttons.view")}</PrimaryButton>
      </Col>
    </Row>
  );
};

AttachedEntity.propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
  }),
  onClick: PropTypes.func.isRequired,
};

const CommentDetails = ({
  initialComment,
  isLastComment,
  discussion,
  prevChildCommentsLevel,
  replaceMainParentComment,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { notification } = App.useApp();

  const { data: currentUser } = useGetCurrentUserQuery();
  const [deleteComment] = useDeleteCommentMutation();
  const [likeComment] = useLikeCommentMutation();
  const [reportCommentById] = useLazyReportCommentQuery();
  const [patchComment] = usePatchCommentMutation();
  const [comment, setComment] = useState(initialComment);
  const [showAddCommentBox, setShowAddCommentBox] = useState(false);
  const [isCommentEdit, setIsCommentEdit] = useState(false);
  const [showChildrenComments, setShowChildrenComments] = useState(false);
  const [childrenCommentsLoadedCount, setChildrenCommentsLoadedCount] =
    useState(0);
  const childRef = useRef();
  const avatarContainerRef = useRef();

  const reportComment = () =>
    reportCommentById(comment.id)
      .unwrap()
      .then(() =>
        notification.success({ message: t("commentReportSuccessfully") }),
      );

  const handleCommentLike = () => {
    likeComment({
      id: comment.id,
      isLiked: !comment.isLikedSelf,
    })
      .unwrap()
      .then((data) =>
        setComment((oldVal) => ({
          ...oldVal,
          isLikedSelf: data.isLiked,
          likesCount: data.likesCount,
        })),
      )
      .catch((error) =>
        notification.error({ message: error.data.errors[0].detail }),
      );
  };

  const handleCommentNotifications = () => {
    patchComment({
      id: comment.id,
      data: { notificationsEnabled: !comment.notificationsEnabled },
    })
      .unwrap()
      .then((data) => {
        notification.success({
          message: data.notificationsEnabled
            ? t("commentNotificationsTurnedOn")
            : t("commentNotificationsTurnedOff"),
        });
        setComment(data);
      });
  };

  const handleDeleteComment = (commentId) => {
    deleteComment(commentId)
      .unwrap()
      .then(() =>
        setComment((oldValue) => ({
          ...oldValue,
          content: null,
          isDeleted: true,
        })),
      );
  };

  const isAdminOrCreator =
    comment &&
    (currentUser.role === Roles.ADMIN.value ||
      comment.createdBy.id === currentUser.id);

  const actions = useMemo(
    () =>
      comment
        ? [
            <span
              key="likes"
              style={{ fontSize: "14px", cursor: "pointer" }}
              className="ms-2"
              onClick={handleCommentLike}
            >
              {comment.isLikedSelf ? (
                <img
                  src="/assets/heart-icon.svg"
                  style={{ width: "14px" }}
                  alt="heart icon"
                />
              ) : (
                <HeartOutlined style={{ color: "rgba(74, 105, 156, 1)" }} />
              )}
              <span style={{ color: "rgba(74, 105, 156, 1)" }} className="ms-2">
                {comment.likesCount}{" "}
                {t("likes", { plural: comment.likesCount === 1 ? "" : "s" })}
              </span>
            </span>,
            <span
              key="comment"
              style={{
                fontSize: "14px",
                color: "rgba(74, 105, 156, 1)",
                cursor: "pointer",
              }}
              onClick={() => setShowAddCommentBox((oldVal) => !oldVal)}
              className="ms-2"
            >
              {t("comment")}
            </span>,
            <span
              key="tools"
              style={{ verticalAlign: "middle" }}
              className="ms-2"
            >
              <Tools
                items={[
                  ...(isAdminOrCreator && !comment.isDeleted
                    ? [
                        {
                          title: t(
                            comment.notificationsEnabled
                              ? "turnOffReplyNotifications"
                              : "turnOnReplyNotifications",
                          ),
                          onClick: handleCommentNotifications,
                        },
                        {
                          title: t("editComment"),
                          onClick: () => setIsCommentEdit(true),
                        },
                        {
                          title: t("deleteComment"),
                          onClick: () => handleDeleteComment(comment.id),
                        },
                      ]
                    : []),
                  ...(isAdminOrCreator
                    ? []
                    : [
                        {
                          title: t("report"),
                          onClick: reportComment,
                        },
                      ]),
                ]}
                listProps={{ width: "180px" }}
                iconProps={{
                  style: {
                    fontSize: "20px",
                    transform: "rotate(90deg)",
                    color: "rgba(74, 105, 156, 1)",
                  },
                }}
              />
            </span>,
          ]
        : [],
    [comment],
  );

  const hasReplies = useMemo(
    () =>
      comment.repliesCount > 0 ||
      comment.repliesCount > childrenCommentsLoadedCount,
    [comment],
  );

  const addCommentCallback = (newComment) => {
    if (childRef.current) {
      childRef.current.setComments((oldVal) => [...oldVal, newComment]);
    } else {
      setShowChildrenComments(true);
    }
    setComment((oldComment) => ({
      ...oldComment,
      repliesCount: oldComment.repliesCount + 1,
    }));
  };

  const renderCommentContent = () => {
    if (comment.isDeleted) {
      return t("commentIsDeleted");
    }
    if (isCommentEdit) {
      return (
        <CommentForm
          initialData={comment}
          hideCommentBox={() => setIsCommentEdit(false)}
          submitCommentCallback={(newComment) => setComment(newComment)}
        />
      );
    } else {
      return (
        <div className="comment-content">
          <QuillInput value={comment.content} />
          {comment.objects.length ||
          comment.narratives.length ||
          comment.surveyViews.length ? (
            <div className="mt-3">
              <div className="mb-3">
                {comment.objects.map((obj) => (
                  <AttachedEntity
                    key={obj.id}
                    entity={obj}
                    onClick={() => navigate(`/objects/${obj.id}`)}
                  />
                ))}
              </div>
              <div>
                {comment.narratives.map((narrative) => (
                  <AttachedEntity
                    key={narrative.id}
                    entity={narrative}
                    onClick={() => navigate(`/narratives/${narrative.id}`)}
                  />
                ))}
              </div>
              <div>
                {comment.surveyViews.map((surveyView) => (
                  <AttachedEntity
                    key={surveyView.id}
                    entity={surveyView}
                    onClick={() =>
                      navigate(
                        `/reports/${surveyView.survey}/report?view=${surveyView.uuid}`,
                      )
                    }
                  />
                ))}
              </div>
            </div>
          ) : null}
        </div>
      );
    }
  };

  const getCommentCreatedTimestamp = (createdAt) => (
    <span style={{ fontSize: "14px" }}>
      {DateTime.fromISO(createdAt).toRelative()}
    </span>
  );

  const handleShowChildrenComment = () => {
    if (!showChildrenComments && prevChildCommentsLevel + 1 === 6) {
      replaceMainParentComment(comment);
    } else {
      setShowChildrenComments(!showChildrenComments);
    }
  };

  return (
    comment && (
      <div>
        <div
          style={{ display: "flex" }}
          className={
            comment.parent && isLastComment ? "pt-4 comment-border" : "pt-4"
          }
        >
          <div ref={avatarContainerRef}>
            <div
              style={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
                alignItems: comment.parent ? "" : "center",
              }}
            >
              <div style={{ display: "flex" }}>
                {comment.parent && (
                  <div
                    style={{
                      margin: "auto 5px auto 0",
                      height: "1px",
                      width: "15px",
                      borderBottom: "1px solid rgba(211, 212, 219, 1)",
                    }}
                  />
                )}
                <Avatar user={comment.createdBy} />
              </div>
              {hasReplies || showChildrenComments ? (
                <>
                  <Divider
                    type="vertical"
                    style={{
                      marginTop: "1px",
                      width: "0.8px",
                      flexGrow: "1",
                      ...(comment.parent && { marginLeft: "40px" }),
                    }}
                  />
                  <img
                    src={
                      showChildrenComments
                        ? "/assets/minus-icon.svg"
                        : "/assets/plus-icon.svg"
                    }
                    style={{
                      borderRadius: 0,
                      width: "20px",
                      height: "20px",
                      marginLeft: comment.parent ? "30px" : 0,
                      cursor: "pointer",
                    }}
                    alt="minus icon"
                    onClick={handleShowChildrenComment}
                  />
                </>
              ) : null}
            </div>
          </div>
          <div className="ps-4 w-100">
            <div>
              <div>
                <a
                  style={{ color: "rgba(74, 105, 156, 1)", fontSize: "14px" }}
                  onClick={() => navigate(`/profile/${comment.createdBy.id}`)}
                >
                  {comment.createdBy.fullName}
                </a>
                {comment.createdBy.id === discussion.createdBy.id && (
                  <Text
                    className="ms-2"
                    style={{
                      textTransform: "uppercase",
                      color: "rgba(91, 146, 229, 1)",
                      fontWeight: 500,
                    }}
                  >
                    {t("author")}
                  </Text>
                )}
                <span
                  className="ms-2"
                  style={{ color: "rgba(139, 141, 152, 1)" }}
                >
                  {getCommentCreatedTimestamp(comment.createdAt)}
                </span>
              </div>
              <div
                className="my-2"
                style={comment.isDeleted ? { color: "grey" } : {}}
              >
                {renderCommentContent()}
              </div>
              <div>{actions}</div>
              <div>
                {showAddCommentBox && (
                  <CommentForm
                    initialData={{ parent: comment.id }}
                    hideCommentBox={() => setShowAddCommentBox(false)}
                    submitCommentCallback={addCommentCallback}
                  />
                )}
              </div>
            </div>
            <div>
              {hasReplies && !showChildrenComments ? (
                <div className="mt-3">
                  <a
                    style={{ color: "rgba(74, 105, 156, 1)" }}
                    onClick={handleShowChildrenComment}
                  >
                    {t("repliesCount", {
                      count: comment.repliesCount,
                      replyGrammaticalNumber:
                        comment.repliesCount > 1 ? t("replies") : t("reply"),
                    })}
                  </a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {avatarContainerRef.current && showChildrenComments ? (
          <div
            style={{
              marginLeft: `${comment.parent ? 40 : avatarContainerRef.current.clientWidth / 2}px`,
            }}
          >
            <Comments
              discussion={discussion}
              parentCommentId={comment.id}
              setParentLoadedComments={setChildrenCommentsLoadedCount}
              ref={childRef}
              childCommentsLevel={prevChildCommentsLevel}
              replaceMainParentComment={replaceMainParentComment}
            />
          </div>
        ) : null}
      </div>
    )
  );
};

CommentDetails.propTypes = {
  initialComment: PropTypes.object.isRequired,
  discussion: PropTypes.shape({
    id: PropTypes.number.isRequired,
    createdBy: PropTypes.object.isRequired,
  }).isRequired,
  isLastComment: PropTypes.bool.isRequired,
  prevChildCommentsLevel: PropTypes.number.isRequired,
  replaceMainParentComment: PropTypes.func.isRequired,
};

export default CommentDetails;
