import { useMemo } from "react";

import * as yup from "yup";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { App, Col, Row, theme } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

import {
  useGetCountriesQuery,
  useGetCurrentUserQuery,
  useGetLanguagesQuery,
  usePatchOrganizationByIdMutation,
} from "../../../redux/service.js";
import Input from "../../../components/form/Input.jsx";
import Upload from "../../../components/form/Upload.jsx";
import FormField from "../../../components/form/FormField.jsx";
import PrimaryButton from "../../../components/PrimaryButton.jsx";
import Select from "../../../components/form/Select.jsx";
import { handleErrors } from "../../../utilities/index.js";
import {
  Chapters,
  OrganizationCommunicationStatuses,
  OrganizationRoles,
  Roles,
} from "../../../utilities/constants.jsx";

const supportedFileFormats = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/bmp",
  "image/webp",
  "image/svg+xml",
];

const URL =
  /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|\/|\?)*)?$/i;

const profileSchema = (currentUser, t) =>
  yup.object().shape({
    email: yup
      .string()
      .typeError(t("form.validation.emailNotValid"))
      .required(t("form.validation.emailRequired")),
    address: yup
      .string()
      .typeError(t("form.validation.addressNotValid"))
      .required(t("form.validation.addressRequired")),
    phoneNumber: yup
      .string()
      .typeError(t("form.validation.contactNumberInvalid")),
    country: yup
      .string()
      .typeError(t("form.validation.countryNotValid"))
      .required(t("form.validation.countryRequired")),
    language: yup.string().typeError(t("form.validation.languageNotValid")),
    size: yup.string().typeError(t("form.validation.sizeNotValid")),
    externalId: yup.string().typeError(t("form.validation.externalIdNotValid")),
    communicatingStatus: yup
      .string()
      .typeError(t("form.validation.communicatingStatusNotValid"))
      .nullable(),
    website: yup
      .string()
      .typeError(t("form.validation.websiteNotValid"))
      .test(
        "url-regex",
        "The URL is not valid.",
        (val) => !val || URL.test(val),
      ),
    logo: yup
      .mixed()
      .test("fileType", t("form.validation.fileFormatInvalid"), (value) => {
        if (!value) {
          return true;
        }
        return supportedFileFormats.includes(value.type);
      })
      .nullable(),
    ...(currentUser?.role === Roles.ADMIN.value
      ? {
          chapter: yup.string().typeError(t("form.validation.chapterNotValid")),
        }
      : {}),
  });

const getDefaultValues = (data) => ({
  name: data.name,
  email: data.email,
  address: data.address,
  phoneNumber: data.phoneNumber || "",
  country: data.country,
  language: data.language,
  size: data.size,
  website: data.website,
  logo: null,
  chapter: data.chapter,
  externalId: data.externalId,
  communicatingStatus: data.communicatingStatus,
});

const ProfileForm = ({ organization }) => {
  const { token } = theme.useToken();
  const { notification } = App.useApp();
  const { t } = useTranslation();

  const [patchOrganizationById] = usePatchOrganizationByIdMutation();
  const { data: countries, isSuccess: isCountriesSuccess } =
    useGetCountriesQuery();
  const { data: languages, isSuccess: isLanguagesSuccess } =
    useGetLanguagesQuery();
  const { data: currentUser } = useGetCurrentUserQuery();

  const form = useForm({
    resolver: yupResolver(profileSchema(currentUser, t)),
    mode: "onChange",
    defaultValues: getDefaultValues(organization),
  });

  const fields = useMemo(
    () => [
      {
        label: t("form.labels.organizationName"),
        field: (
          <Input
            name="name"
            disabled={currentUser?.role !== Roles.ADMIN.value}
            placeholder={t("form.placeholders.university")}
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.contactEmail"),
        required: true,
        field: (
          <Input
            name="email"
            placeholder={t("form.placeholders.email")}
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.organizationAddress"),
        required: true,
        field: (
          <Input
            name="address"
            placeholder={t("form.placeholders.address")}
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.contactNumber"),
        field: (
          <Input
            name="phoneNumber"
            htmlType="phone"
            placeholder={t("form.placeholders.phoneNumber")}
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.country"),
        required: true,
        field: (
          <Select
            name="country"
            placeholder={t("form.placeholders.selectCountry")}
            options={isCountriesSuccess ? countries : []}
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.language"),
        field: (
          <Select
            name="language"
            placeholder={t("form.placeholders.selectLanguage")}
            options={isLanguagesSuccess ? languages : []}
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.organizationSize"),
        field: (
          <Select
            name="size"
            placeholder={t("form.placeholders.size")}
            options={[
              {
                label: "From 1 to 10 employees",
                value: "MICRO",
              },
              {
                label: "From 11 to 50 employees",
                value: "SMALL",
              },
              {
                label: "From 51 to 250 employees",
                value: "MEDIUM",
              },
              {
                label: "From 251 to 1000 employees",
                value: "LARGE",
              },
              {
                label: "1000+ employees",
                value: "ENTERPRISE",
              },
            ]}
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.website"),
        field: (
          <Input
            name="website"
            placeholder={t("form.placeholders.website")}
            control={form.control}
          />
        ),
      },
      {
        label: t("form.labels.chapter"),
        field: (
          <Select
            name="chapter"
            disabled={currentUser?.role === Roles.USER.value}
            placeholder={t("form.placeholders.selectChapter")}
            options={Chapters.asList()}
            control={form.control}
          />
        ),
      },
      ...(currentUser.role === Roles.ADMIN.value
        ? [
            {
              label: t("form.labels.communicatingStatus"),
              field: (
                <Select
                  name="communicatingStatus"
                  options={OrganizationCommunicationStatuses.asList()}
                  placeholder={t("form.placeholders.communicatingStatus")}
                  control={form.control}
                />
              ),
            },
            {
              label: t("form.labels.externalId"),
              field: (
                <Input
                  name="externalId"
                  htmlType="number"
                  placeholder={t("form.placeholders.externalId")}
                  control={form.control}
                />
              ),
            },
          ]
        : []),
    ],
    [currentUser],
  );

  const onSubmit = async () => {
    const isValid = await form.trigger();

    if (isValid) {
      const values = form.getValues();
      const formData = new FormData();
      Object.keys(values)
        .filter(
          (i) =>
            (i !== "logo" || values[i] instanceof File) &&
            ![undefined, null].includes(values[i]),
        )
        .forEach((i) => formData.set(i, values[i]));
      patchOrganizationById({ id: organization.id, data: formData })
        .unwrap()
        .then((data) => {
          notification.success({ message: t("messages.profileUpdated") });
          form.reset(getDefaultValues(data));
        })
        .catch((errors) => handleErrors(errors, form.setError, notification));
    }
  };

  const canUserEdit = useMemo(
    () =>
      currentUser &&
      (currentUser.role === Roles.ADMIN.value ||
        [
          OrganizationRoles.ADMIN.value,
          OrganizationRoles.SUPERVISOR.value,
        ].includes(currentUser.organizationRole)),
    [currentUser],
  );

  return (
    isCountriesSuccess && (
      <div className="mb-5">
        <div className="mt-4 mb-3">
          <Upload
            name="logo"
            buttonTitle="Upload logo"
            control={form.control}
            imageUrl={organization.logo}
            supportedFormats={supportedFileFormats}
            listType="picture-circle"
            isEdit={canUserEdit}
          />
        </div>
        <Row>
          <Col span={6} className="me-5">
            {fields.slice(0, 5).map((i) => (
              <FormField key={i.label} {...i} isEdit={canUserEdit} />
            ))}
          </Col>
          <Col span={6}>
            {fields.slice(5).map((i) => (
              <FormField key={i.label} {...i} isEdit={canUserEdit} />
            ))}
          </Col>
        </Row>
        {canUserEdit && (
          <Row className="mt-4">
            <Col className="me-3">
              <PrimaryButton onClick={onSubmit}>
                {t("buttons.save")}
              </PrimaryButton>
            </Col>
            <PrimaryButton
              style={{ background: token.colorBackgroundSecondary }}
              onClick={form.reset}
            >
              {t("buttons.cancel")}
            </PrimaryButton>
          </Row>
        )}
      </div>
    )
  );
};

ProfileForm.propTypes = {
  organization: PropTypes.object.isRequired,
};

export default ProfileForm;
