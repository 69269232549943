import { useEffect, useState } from "react";

import { App, Col, Divider, Row, Spin, theme, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { LeftOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";

import Select from "../../../components/form/Select.jsx";
import { Roles, SharingRights } from "../../../utilities/constants.jsx";
import RangePicker from "../../../components/form/RangePicker.jsx";
import {
  useGetCountriesQuery,
  useGetCurrentUserQuery,
  useGetLanguagesQuery,
  useGetSubjectsQuery,
  useLazyGetMembershipsQuery,
  usePublishNarrativeMutation,
} from "../../../redux/service.js";
import Avatar from "../../../components/Avatar.jsx";
import FormField from "../../../components/form/FormField.jsx";
import Input from "../../../components/form/Input.jsx";
import PrimaryButton from "../../../components/PrimaryButton.jsx";
import NarrativePreview from "./NarrativesPreview.jsx";
import NarrativeChapters from "./NarrativeChapters.jsx";
import { handleErrors } from "../../../utilities/index.js";

const { Title, Text } = Typography;

const narrativeSchema = (t) =>
  yup.object().shape({
    title: yup
      .string()
      .typeError(t("form.validation.titleNotValid"))
      .required(t("form.validation.titleRequired")),
    impactPurpose: yup
      .string()
      .typeError(t("form.validation.impactPurposeNotValid"))
      .required(t("form.validation.impactPurposeRequired")),
    impactStatement: yup
      .string()
      .typeError(t("form.validation.impactStatementNotValid"))
      .required(t("form.validation.impactStatementRequired")),
    contributors: yup
      .array()
      .of(yup.number().typeError(t("form.validation.mustSelectContributor")))
      .typeError(t("form.validation.contributorsNotValid")),
    language: yup
      .string()
      .typeError(t("form.validation.languageNotValid"))
      .required(t("form.validation.languageRequired")),
    country: yup
      .string()
      .typeError(t("form.validation.countryNotValid"))
      .required(t("form.validation.countryRequired")),
    subjects: yup
      .array()
      .typeError(t("form.validation.subjectNotValid"))
      .required(t("form.validation.subjectRequired"))
      .min(1, t("form.validation.subjectsMinimum")),
    sharingRights: yup
      .string()
      .typeError(t("form.validation.sharingRightsNotValid"))
      .required(t("form.validation.sharingRightsRequired")),
    applicableDateRange: yup
      .array()
      .typeError(t("form.validation.applicableDateRangeNotValid"))
      .required(t("form.validation.applicableDateRangeRequired")),
    chapters: yup.array().typeError(t("form.validation.chaptersNotValid")),
  });

const narrativeDefaultValues = {
  title: null,
  impactPurpose: null,
  impactStatement: null,
  language: null,
  sharingRights: null,
  chapters: [],
  contributors: [],
  subjects: [],
  applicableDateRange: null,
  country: null,
};

const NarrativeForm = ({ narrative, onSubmit }) => {
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const { notification } = App.useApp();
  const location = useLocation();
  const navigate = useNavigate();

  const { data: currentUser } = useGetCurrentUserQuery();
  const [getMemberships, membershipsResult] = useLazyGetMembershipsQuery();
  const { data: subjectsResponse, isSuccess: subjectsIsSuccess } =
    useGetSubjectsQuery({ pagination: "off" });
  const [publishNarrative] = usePublishNarrativeMutation();
  const { data: countries, isSuccess: isCountriesSuccess } =
    useGetCountriesQuery();
  const { data: languages, isSuccess: isLanguagesSuccess } =
    useGetLanguagesQuery();
  const [narrativePreviewModal, setNarrativePreviewModal] = useState({
    open: false,
    data: null,
  });
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const form = useForm({
    resolver: yupResolver(narrativeSchema(t)),
    mode: "onChange",
    defaultValues: narrativeDefaultValues,
  });


  useEffect(() => {
    if (currentUser) {
      if (currentUser.role === Roles.ADMIN.value) {
        navigate("/");
        return;
      }

      getMemberships({
        organization: currentUser.organization.id,
        pagination: "off",
      });
    }
  }, [currentUser]);

  // If edit, set all narrative data. Also set initial data if any has been passed
  useEffect(() => {
    let data = {};
    if (narrative && currentUser) {
      data = {
        ...narrative,
        chapters: narrative.chapters,
        applicableDateRange: narrative.applicableDateRange
          ? [
              dayjs(narrative.applicableDateRange[0]),
              dayjs(narrative.applicableDateRange[1]),
            ]
          : null,
        country: narrative?.country || currentUser.organization?.country,
        language: narrative?.language || currentUser.organization.language,
        subjects: narrative.subjects.map((i) => i.name),
      };
    }
    form.reset({
      ...data,
      ...(location.state?.initialData || {}),
    });
  }, [location.state, narrative, currentUser]);

  // Set the unsaved changed flag when the user inputs values
  useEffect(() => {
    if (form.formState.isDirty) {
      setHasUnsavedChanges(true);
    }
  }, [form.formState.isDirty]);

  const narrativeFields = [
    {
      label: t("form.labels.title"),
      field: (
        <Input
          name="title"
          placeholder={t("form.placeholders.narrativeTitle")}
          control={form.control}
        />
      ),
    },
    {
      label: t("form.labels.impactPurpose"),
      tooltipContent: (
        <div className="p-2">
          <Title
            className="mb-2"
            style={{ color: token.colorTextThird }}
            level={5}
          >
            {t("labels.impactPurpose")}
          </Title>
          <Text style={{ color: token.colorTextThird }}>
            {t("impactPurposeHelpText")}
          </Text>
        </div>
      ),
      field: (
        <Input
          name="impactPurpose"
          rows={7}
          type="richtexteditor"
          placeholder={t("form.placeholders.impactPurpose")}
          control={form.control}
        />
      ),
    },
    {
      label: t("form.labels.impactStatement"),
      tooltipContent: (
        <div className="p-2">
          <Title
            className="mb-2"
            style={{ color: token.colorTextThird }}
            level={5}
          >
            {t("labels.impactStatement")}
          </Title>
          <Text style={{ color: token.colorTextThird }}>
            {t("impactStatementHelpText")}
          </Text>
        </div>
      ),
      field: (
        <Input
          name="impactStatement"
          rows={7}
          type="richtexteditor"
          placeholder={t("form.placeholders.impactStatement")}
          control={form.control}
        />
      ),
    },
  ];

  const narrativeSettingsFields = [
    {
      label: t("form.labels.applicableDateRange"),
      field: <RangePicker name="applicableDateRange" control={form.control} />,
    },
    {
      label: t("form.labels.language"),
      field: (
        <Select
          name="language"
          options={isLanguagesSuccess ? languages : []}
          placeholder={t("form.placeholders.language")}
          control={form.control}
        />
      ),
    },
    {
      label: t("form.labels.country"),
      field: (
        <Select
          name="country"
          options={isCountriesSuccess ? countries : []}
          placeholder={t("form.placeholders.countrySelect")}
          control={form.control}
        />
      ),
    },
    {
      label: t("form.labels.sharingRights"),
      tooltipContent: (
        <div className="p-2">
          <Title
            className="mb-2"
            style={{ color: token.colorTextThird }}
            level={4}
          >
            {t("labels.sharingRights")}
          </Title>
          <div className="mb-2">
            <Title
              className="mb-1"
              style={{ color: token.colorTextThird }}
              level={5}
            >
              {t("labels.public")}
            </Title>
            <Text>{t("sharingRightsPublicHelpText")}</Text>
          </div>
          <div className="mb-2">
            <Title
              className="mb-"
              style={{ color: token.colorTextThird }}
              level={5}
            >
              {t("labels.private")}
            </Title>
            <Text>{t("sharingRightsPrivateHelpText")}</Text>
          </div>
          <div className="mb-2">
            <Title
              className="mb-1"
              style={{ color: token.colorTextThird }}
              level={5}
            >
              {t("labels.prmeCommunity")}
            </Title>
            <Text>{t("sharingRightsPrmeCommunityHelpText")}</Text>
          </div>
        </div>
      ),
      field: (
        <Select
          name="sharingRights"
          options={SharingRights.asList()}
          placeholder={t("form.placeholders.sharingRights")}
          control={form.control}
        />
      ),
    },
    {
      label: t("form.labels.subjects"),
      field: (
        <Select
          mode="tags"
          name="subjects"
          options={
            subjectsIsSuccess
              ? subjectsResponse.map((i) => ({ value: i.name, label: i.name }))
              : []
          }
          placeholder={t("form.placeholders.subjectsSelect")}
          control={form.control}
        />
      ),
    },
  ];

  const saveNarrative = async (showSaveMessage = true) => {
    setIsSaving(true);
    const isValid = await form.trigger();
    let responseData;
    if (isValid) {
      const values = form.getValues();
      values.startDate = values.applicableDateRange[0].format("YYYY-MM-DD");
      values.endDate = values.applicableDateRange[1].format("YYYY-MM-DD");
      values.organization = currentUser.organization.id;
      values.chapters = (values?.chapters || []).map((i) => ({
        ...i,
        attachedObjects: (i?.attachedObjects || []).map((obj) => obj.id),
      }));
      values.subjects = values.subjects.map((i) => ({ name: i }));

      try {
        responseData = await onSubmit(values);
        setHasUnsavedChanges(false);
        if (showSaveMessage) {
          notification.success({ message: t("narrativeSaved") });
        }

        if (location?.state?.previousLocation) {
          navigate(location.state.previousLocation);
        } else {
          navigate(`/narratives/${responseData.id}`);
        }
      } catch (errors) {
        handleErrors(errors, form.setError, notification);
      }
    } else {
      if (form.formState.errors?.chapters) {
        notification.error({ message: t("form.validation.chaptersRequired") });
      }
    }
    setIsSaving(false);
    return {
      isValid,
      responseData,
    };
  };

  const handlePublishNarrative = async () => {
    setIsSaving(true);
    saveNarrative(false)
      .then((response) => {
        if (response.isValid) {
          publishNarrative(response.responseData.id)
            .unwrap()
            .then(() => {
              notification.success({
                message: t("narrativePublished"),
              });
            });
        }
      })
      .finally(() => setIsSaving(false));
  };

  const handleNarrativePreview = () => {
    const data = form.getValues();
    data.contributors = data.contributors
      .map((i) => {
        const userMember = membershipsResult.data.find(
          (member) => i === member.user.id,
        );
        return userMember?.user || null;
      })
      .filter((i) => i !== null);

    setNarrativePreviewModal({
      open: true,
      data,
    });
  };

  return (
    <div>
      {location.state?.initialData?.title && (
        <div className="mb-4">
          <Text
            onClick={() => navigate(location.state.previousLocation)}
            style={{
              color: "rgba(74, 105, 156, 1)",
              fontWeight: "500",
              cursor: "pointer",
            }}
          >
            <LeftOutlined color="rgba(74, 105, 156, 1)" />{" "}
            {location.state?.initialData.title}
          </Text>
        </div>
      )}
      <Row>
        <Col span={17} className="pe-5 mb-5">
          <Row className="mb-3">
            <Col span={12}>
              <Text>
                {t("thisNarrativeIs")}{" "}
                {narrative.isPublished ? t("labels.published") : t("aDraft")}
              </Text>
              <Title
                level={2}
                style={{ fontWeight: "bold", color: token.colorPrimaryText }}
                className="mt-2 mb-3"
              >
                {t(narrative.id ? "editNarrative" : "labels.createNarrative")}
              </Title>
            </Col>
            <Col
              span={12}
              style={{
                backgroundColor: hasUnsavedChanges
                  ? "rgba(250,185,20,0.56)"
                  : "rgba(233, 240, 252, 1)",
              }}
            >
              {hasUnsavedChanges && (
                <div style={{ textAlign: "center", paddingTop: "10px" }}>
                  <Title level={5} style={{ fontSize: "16px" }}>
                    {t("unsavedChanges")}
                    <br />
                    {t("saveUnsavedChanges")}
                  </Title>
                </div>
              )}
              <Row
                className="py-3 px-2"
                gutter={[0, 10]}
                style={{ justifyContent: "center" }}
              >
                <Col>
                  <PrimaryButton
                    type="secondary"
                    onClick={handleNarrativePreview}
                  >
                    {t("buttons.preview")}
                  </PrimaryButton>
                </Col>
                <Col className="mx-3">
                  <PrimaryButton
                    onClick={saveNarrative}
                    disabled={isSaving}
                    type="secondary"
                  >
                    {isSaving ? (
                      <div className="d-flex justify-content-center align-items-center h-100 px-3">
                        <Spin size="small" />
                      </div>
                    ) : (
                      t("buttons.save")
                    )}
                  </PrimaryButton>
                </Col>
                {!narrative.isPublished && (
                  <Col>
                    <PrimaryButton disabled={isSaving} onClick={handlePublishNarrative}>
                      {t("buttons.publish")}
                    </PrimaryButton>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          {currentUser && (
            <Row>
              <Col span={12} className="pe-4">
                <FormField
                  label={t("form.labels.owner")}
                  isEdit={false}
                  field={
                    <div>
                      <Avatar user={narrative?.createdBy || currentUser} />
                      <Text
                        className="ms-2"
                        style={{ fontSize: "18px", fontWeight: "bold" }}
                        level={5}
                      >
                        {(narrative?.createdBy || currentUser).fullName}
                      </Text>
                    </div>
                  }
                />
              </Col>
              <Col span={12} className="ps-4">
                {membershipsResult.isSuccess && (
                  <FormField
                    label={t("form.labels.contributors")}
                    tooltipContent={
                      <div className="p-2">
                        <Title
                          className="mb-2"
                          style={{ color: token.colorTextThird }}
                          level={5}
                        >
                          {t("labels.contributor")}
                        </Title>
                        <Text style={{ color: token.colorTextThird }}>
                          {t("contributorHelpText")}
                        </Text>
                      </div>
                    }
                    field={
                      <Select
                        name="contributors"
                        mode="multiple"
                        options={membershipsResult.data.map((member) => ({
                          value: member.user.id,
                          label: member.user.fullName,
                        }))}
                        control={form.control}
                        placeholder={t("form.placeholders.contributors")}
                      />
                    }
                  />
                )}
              </Col>
            </Row>
          )}
          {narrativeFields.map((i) => (
            <FormField
              key={i.label}
              label={i.label}
              tooltipContent={i?.tooltipContent}
              required
              field={i.field}
            />
          ))}
          <Divider />
          <NarrativeChapters
            narrativeForm={form}
            callbackOnChange={() => setHasUnsavedChanges(true)}
          />
        </Col>
        <Col
          span={7}
          style={{ borderLeft: "1.5px solid rgba(5, 5, 5, 0.06)" }}
          className="ps-5"
        >
          <Title
            level={4}
            style={{ fontWeight: "bold", color: token.colorPrimaryText }}
            className="mt-3 mb-4"
          >
            {t("narrativeSettings")}
          </Title>
          {narrativeSettingsFields.map((i) => (
            <div key={i.label}>
              <FormField
                label={i.label}
                required
                tooltipContent={i?.tooltipContent}
                field={i.field}
              />
              <Divider />
            </div>
          ))}
        </Col>
      </Row>
      <NarrativePreview
        data={narrativePreviewModal.data}
        open={narrativePreviewModal.open}
        onCancel={() =>
          setNarrativePreviewModal({
            open: false,
            data: null,
          })
        }
      />
    </div>
  );
};

NarrativeForm.propTypes = {
  narrative: PropTypes.shape({
    id: PropTypes.number,
    contributors: PropTypes.array,
    title: PropTypes.string,
    impactPurpose: PropTypes.string,
    impactStatement: PropTypes.string,
    applicableDateRange: PropTypes.array,
    language: PropTypes.string,
    subjects: PropTypes.array,
    chapters: PropTypes.array,
    isPublished: PropTypes.bool,
    country: PropTypes.string,
    sharingRights: PropTypes.string,
    organization: PropTypes.number,
    createdBy: PropTypes.object,
  }),
  onSubmit: PropTypes.func.isRequired,
};

NarrativeForm.defaultProps = {
  narrative: narrativeDefaultValues,
};

export default NarrativeForm;
