import { useEffect, useState } from "react";

import { App, Col, Row, Spin, theme, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import {
  useLazyGetPrinciplesQuery,
  useLazyGetSurveyStatisticsQuery,
  useLazyGetSurveyViewByIdQuery,
} from "../../../../redux/service.js";
import { SharingRights } from "../../../../utilities/constants.jsx";
import SurveyViewDetails from "./SurveyViewDetails.jsx";
import { InsufficientPermissions } from "./SurveyView.jsx";

const { Title } = Typography;

export const SurveyViewSnippet = ({ surveyViewUuid }) => {
  const { notification } = App.useApp();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token } = theme.useToken();

  const [getSurveyViewById, surveyViewResponse] =
    useLazyGetSurveyViewByIdQuery();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [getPrinciples, principlesResponse] = useLazyGetPrinciplesQuery();
  const [getSurveyStatics, surveyStatisticsResponse] =
    useLazyGetSurveyStatisticsQuery();
  const [activeKeys, setActiveKeys] = useState([]);

  const getSurveyViewData = async () => {
    const principles = [];
    const questions = [];

    const response = await getSurveyViewById(surveyViewUuid);
    if (
      response.data.sharingRights !== SharingRights.PUBLIC.value &&
      !isAuthenticated
    )
      throw new InsufficientPermissions(t("surveyViewNotPublic"), "/login");

    response.data.answers.forEach((i) => {
      principles.push(i.principle);
      questions.push(i.question);
    });
    const surveyId = response.data.survey;

    getPrinciples({
      ids: principles,
      question_ids: questions,
      survey: surveyId,
    });
    getSurveyStatics({
      survey: surveyId,
      organization: response.data.organization.id,
    });
  };

  useEffect(() => {
    getSurveyViewData().catch((err) => {
      if (err instanceof InsufficientPermissions) {
        notification.error({ message: err.message, key: err.message });
        navigate(err.navigateTo);
        return;
      }
      throw err;
    });
  }, [isAuthenticated]);

  useEffect(() => {
    if (principlesResponse.isSuccess) {
      setActiveKeys(
        principlesResponse.data.results
          .slice()
          .sort((a, b) => a.index - b.index)
          .map((principle) => principle.id),
      );
    }
  }, [principlesResponse]);

  if (surveyViewResponse.isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spin size="large" />
      </div>
    );
  }

  if (surveyViewResponse.isError) {
    return <Navigate to="/" />;
  }

  return surveyViewResponse.isSuccess ? (
    <div style={{ width: "90%" }}>
      <Row>
        <Col xl={13} md={13} sm={24} style={{ display: "flex" }}>
          <Title
            className="ms-3"
            level={5}
            style={{ alignSelf: "end", color: token.colorTextThird }}
          >
            {surveyViewResponse.data.organization?.name ||
              surveyViewResponse.data.createdBy?.fullName}
          </Title>
        </Col>
      </Row>
      {activeKeys.length > 0 && (
        <SurveyViewDetails
          principlesResponse={principlesResponse}
          surveyStatisticsResponse={surveyStatisticsResponse}
          surveyViewResponse={surveyViewResponse}
        />
      )}
    </div>
  ) : null;
};

SurveyViewSnippet.propTypes = {
  surveyViewUuid: PropTypes.string.isRequired,
};

export default SurveyViewSnippet;
