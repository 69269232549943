import { useEffect } from "react";

import { App, Col, Collapse, Row, Spin, theme, Typography } from "antd";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { Helmet } from "react-helmet-async";
import {
  DownOutlined,
  EyeOutlined,
  HeartOutlined,
  LikeOutlined,
  UpOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import _ from "lodash";

import Avatar from "../../components/Avatar.jsx";
import {
  useGetCurrentUserQuery,
  useGetDiscussionByIdQuery,
  useLazyIncrementDiscussionViewsQuery,
  useLazyGetTopicsQuery,
  useLikeDiscussionMutation,
} from "../../redux/service.js";
import {
  setBreadcrumbs,
} from "../../redux/utils/reducer.js";
import PrimaryButton from "../../components/PrimaryButton.jsx";
import NarrativeSnippet from "../narratives/components/NarrativeSnippet.jsx";
import ObjectSnippet from "../objects/components/ObjectSnippet.jsx";
import Comments from "./components/Comments.jsx";
import DiscussionTools from "./components/DiscussionTools.jsx";
import SurveyViewSnippet from "../survey/components/survey-views/SurveyViewSnippet.jsx";
import QuillInput from "../../components/form/QuillInput.jsx";

const { Title, Text } = Typography;

const MetaTags = ({ discussion }) => (
  <Helmet>
    <title>{`PRME Commons - ${discussion.title}`}</title>
    <meta name="og:title" content={`PRME Commons - ${discussion.title}`} />
    <meta name="og:url" content={window.location.href} />
    <meta name="og:article" content="article" />
    <meta name="og:description" content={_.truncate(discussion.content)} />
  </Helmet>
);

MetaTags.propTypes = {
  discussion: PropTypes.object.isRequired,
};

const getCreationDate = (createdAt) => {
  if (DateTime.fromISO(createdAt).diff(DateTime.now(), "days").days > 2) {
    return DateTime.fromISO(createdAt).toRelative();
  } else {
    return DateTime.fromISO(createdAt).toFormat("dd MMM yyyy");
  }
};

const SingleDiscussion = () => {
  const { discussionId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const { notification } = App.useApp();

  const {
    data: discussion,
    isSuccess,
    isError,
    isLoading,
  } = useGetDiscussionByIdQuery(discussionId);
  const [incrementDiscussionViews] = useLazyIncrementDiscussionViewsQuery();
  const [getTopics, topicsResponse] = useLazyGetTopicsQuery();
  const [likeDiscussion] = useLikeDiscussionMutation();
  const { data: currentUser, isSuccess: isCurrentUserSuccess } =
    useGetCurrentUserQuery();

  useEffect(() => {
    if (
      isSuccess &&
      isCurrentUserSuccess &&
      discussion.createdBy.id !== currentUser.id
    ) {
      setTimeout(() => {
        incrementDiscussionViews(discussion.id);
      }, 15000);
    }
  }, [isSuccess, isCurrentUserSuccess]);

  useEffect(() => {
    if (isSuccess) {
      getTopics({ ids: discussion.topics, pagination: "off" });
      dispatch(
        setBreadcrumbs([
          {
            title: t("community"),
            to: "/community",
          },
          {
            title: topicsResponse.isSuccess
              ? topicsResponse.data
                  .filter((i) => discussion.topics.includes(i.id))
                  .map((i) => i.title)
                  .join(", ")
              : null,
          },
          {
            title: discussion.title,
          },
        ]),
      );
    }

    return () => dispatch(setBreadcrumbs(null));
  }, [topicsResponse.isSuccess, isSuccess]);

  const openUserProfile = () => {
    navigate(`/profile/${discussion.createdBy.id}`);
  };

  const handleLinkedInSharing = () => {
    window.open(
      `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`,
      "_blank",
    );
  };

  const handleDiscussionLike = () => {
    likeDiscussion({
      id: discussion.id,
      isLiked: !discussion.isLikedSelf,
    })
      .unwrap()
      .catch((error) =>
        notification.error({ message: error.data.errors[0].detail }),
      );
  };

  const collapseExpandButtonIcon = ({ isActive }) =>
    isActive ? <DownOutlined /> : <UpOutlined />;

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spin size="large" />
      </div>
    );
  }

  if (isError) {
    return <Navigate to="/community" />;
  }

  return (
    isSuccess &&
    isCurrentUserSuccess && (
      <div className="mb-5">
        <MetaTags discussion={discussion} />
        <div style={{ borderBottom: "1px solid rgba(1, 1, 46, 0.134)" }}>
          <Row className="pb-3">
            <Col className="ps-2 pe-3" span={23}>
              <div style={{ display: "flex" }}>
                <div className="me-3">
                  <Avatar user={discussion.createdBy} />
                </div>
                <div>
                  <Text
                    style={{
                      display: "block",
                      color: "rgba(139, 141, 152, 1)",
                    }}
                  >
                    {t("postedOn")} {getCreationDate(discussion.createdAt)}
                  </Text>
                  <Text
                    style={{
                      display: "block",
                      color: "rgba(139, 141, 152, 1)",
                    }}
                  >
                    {t("by")}{" "}
                    <a
                      style={{ color: "rgba(74, 105, 156, 1)" }}
                      onClick={openUserProfile}
                    >
                      {discussion.createdBy.fullName}
                    </a>
                  </Text>
                </div>
              </div>
            </Col>
            <Col span={1}>
              <DiscussionTools
                discussion={discussion}
                iconProps={{
                  style: {
                    fontSize: "30px",
                    fontWeight: "bold",
                    transform: "rotate(90deg)",
                    color: "rgba(74, 105, 156, 1)",
                  },
                }}
              />
            </Col>
          </Row>
        </div>
        <Row className="mt-4">
          <Col span={20} className="ps-3 pe-5">
            <Title
              className="mb-2"
              level={3}
              style={{ color: token.colorTextThird, fontWeight: "bold" }}
            >
              {discussion.title}
            </Title>
            <div className="mt-4 mb-5">
              <QuillInput value={discussion.content} />
            </div>
            <div className="mt-5">
              {discussion.narratives.length > 0 && (
                <div className="mb-4">
                  {discussion.narratives.map((i) => (
                    <Collapse
                      key={i.id}
                      className="mt-3 mb-4"
                      bordered={false}
                      expandIconPosition="end"
                      expandIcon={collapseExpandButtonIcon}
                      items={[
                        {
                          key: i.id,
                          label: (
                            <Title
                              level={5}
                              style={{ color: token.colorTextThird }}
                            >
                              {i.title}
                            </Title>
                          ),
                          children: <NarrativeSnippet narrative={i} />,
                        },
                      ]}
                    />
                  ))}
                </div>
              )}
              {discussion.objects.length > 0 && (
                <div className="mt-3 mb-4">
                  {discussion.objects.map((i) => (
                    <Collapse
                      key={i.id}
                      className="mt-3 mb-4"
                      expandIconPosition="end"
                      bordered={false}
                      expandIcon={collapseExpandButtonIcon}
                      items={[
                        {
                          key: i.id,
                          label: (
                            <Title
                              level={5}
                              style={{ color: token.colorTextThird }}
                            >
                              {i.title}
                            </Title>
                          ),
                          children: <ObjectSnippet object={i} />,
                        },
                      ]}
                    />
                  ))}
                </div>
              )}
              {discussion.surveyViews.length > 0 && (
                <div className="mt-3 mb-4">
                  {discussion.surveyViews.map((i) => (
                    <Collapse
                      key={i.uuid}
                      className="mt-3 mb-3"
                      expandIconPosition="end"
                      bordered={false}
                      expandIcon={collapseExpandButtonIcon}
                      items={[
                        {
                          key: i.uuid,
                          label: (
                            <Title
                              level={3}
                              style={{ color: token.colorTextThird }}
                            >
                              {i.title}
                            </Title>
                          ),
                          children: (
                            <SurveyViewSnippet surveyViewUuid={i.uuid} />
                          ),
                        },
                      ]}
                    />
                  ))}
                </div>
              )}
            </div>
            <div style={{ position: "absolute", bottom: 0, right: "50px" }}>
              {discussion.createdBy.id !== currentUser.id && (
                <Text
                  style={{
                    fontSize: "17px",
                    color: "rgba(74, 105, 156, 1)",
                    cursor: "pointer",
                  }}
                  onClick={handleDiscussionLike}
                >
                  {discussion.isLikedSelf ? (
                    <img
                      src="/assets/heart-icon.svg"
                      style={{ width: "14px" }}
                      alt="heart icon"
                    />
                  ) : (
                    <HeartOutlined style={{ color: "rgba(74, 105, 156, 1)" }} />
                  )}
                  <span className="ms-2">{t("likeDiscussion")}</span>
                </Text>
              )}
            </div>
          </Col>
          <Col span={4}>
            {[
              {
                icon: (
                  <img
                    src="/assets/comment.svg"
                    alt="comment"
                    style={{
                      filter:
                        "invert(59%) sepia(10%) saturate(275%) hue-rotate(193deg) brightness(93%) contrast(88%)",
                    }}
                  />
                ),
                text: t("commentsCount", {
                  count: discussion.commentsCount,
                  plural: discussion.commentsCount === 1 ? "" : "s",
                }),
              },
              {
                icon: <EyeOutlined />,
                text: t("viewsCount", {
                  count: discussion.views,
                  plural: discussion.views === 1 ? "" : "s",
                }),
              },
              {
                icon: <LikeOutlined />,
                text: t("likesCount", {
                  count: discussion.likesCount,
                  plural: discussion.likesCount === 1 ? "" : "s",
                }),
              },
            ].map((i) => (
              <Text
                key={i.text}
                className="mb-2"
                style={{
                  display: "block",
                  color: "rgba(139, 141, 152, 1)",
                  fontSize: "15px",
                }}
              >
                <span className="me-2">{i.icon}</span>
                {i.text}
              </Text>
            ))}
            <div className="mt-3">
              <PrimaryButton type="secondary" onClick={handleLinkedInSharing}>
                <Text level={5} style={{ color: "white" }}>
                  <img
                    src="/assets/linkedin.svg"
                    alt="linkedin icon"
                    className="me-1"
                  />{" "}
                  {t("shareDiscussion")}
                </Text>
              </PrimaryButton>
            </div>
            <div className="mt-4">
              <Title level={5} style={{ color: token.colorTextThird }}>
                {t("subjectTags")}
              </Title>
              {discussion.subjects.map((subject) => (
                <Title
                  level={5}
                  key={subject}
                  className="mt-2"
                  style={{ color: "rgba(0, 37, 158, 0.797)", fontSize: "14px" }}
                >
                  {subject.name}
                </Title>
              ))}
            </div>
          </Col>
        </Row>
        <Comments discussion={discussion} />
      </div>
    )
  );
};

export default SingleDiscussion;
