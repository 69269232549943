import { useEffect, useMemo, useState } from "react";

import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  Col,
  Progress,
  Row,
  Select,
  Spin,
  theme,
  Tooltip,
  Typography,
} from "antd";
import { Column } from "@ant-design/charts";
import PropTypes from "prop-types";

import {
  useLazyGetSurveyAdminStatisticsQuery,
  useLazyGetSurveyByIdQuery,
  usePaginateFilterSurveysQuery,
} from "../../../redux/service.js";
import Tag from "../../../components/Tag.jsx";
import {
  Chapters,
  SurveyAudience,
  SurveyStatus,
} from "../../../utilities/constants.jsx";
import StatisticsBox from "./StatisticsBox.jsx";
import CountriesMap from "./CountriesMap.jsx";

const { Title, Text } = Typography;

const SubmissionsProgress = ({ countryData }) => {
  const { t } = useTranslation();
  const { token } = theme.useToken();

  return (
    <Tooltip
      className="country-data-tooltip"
      showArrow={false}
      title={
        <div className="px-1 py-1">
          <Title
            level={5}
            className="tooltip-country"
            style={{ color: token.colorTextThird }}
          >
            {countryData.name}
          </Title>
          <div style={{ color: token.colorTextThird }}>
            <div>
              <Title
                level={5}
                style={{ fontSize: "18px" }}
                className="map-popup-bold"
              >
                {countryData.entitiesCount}
              </Title>
              <Text className="map-popup-text">
                {" "}
                {t("schools", {
                  plural: countryData.entitiesCount === 1 ? "" : "s",
                })}
              </Text>
            </div>
            <div>
              <Title
                level={5}
                style={{ fontSize: "18px" }}
                className="map-popup-bold"
              >
                {countryData.submittedSurveysCount}
              </Title>
              <Text className="map-popup-text">
                {" "}
                {t("reports", {
                  plural: countryData.submittedSurveysCount === 1 ? "" : "s",
                })}
              </Text>
            </div>
          </div>
        </div>
      }
    >
      <Text style={{ color: "rgba(96, 100, 108, 1)" }}>{countryData.name}</Text>
      <Progress
        className="submissions-progress"
        strokeColor="rgba(28, 49, 80, 1)"
        percent={
          (countryData.submittedSurveysCount / countryData.entitiesCount) * 100
        }
      />
    </Tooltip>
  );
};

SubmissionsProgress.propTypes = {
  countryData: PropTypes.object.isRequired,
};

const ReportingStatistics = ({ setSelectedSurveyCallback }) => {
  const { t } = useTranslation();
  const { token } = theme.useToken();

  const [selectedSurvey, setSelectedSurvey] = useState();
  const { data: surveysData, isSuccess: isSurveysSuccess } =
    usePaginateFilterSurveysQuery({
      pagination: "off",
      ordering: "-start_date",
    });
  const [
    getSurveyById,
    {
      data: selectedSurveyData,
      isSuccess: isSelectedSurveySuccess,
      isLoading: isSurveyAdminStatisticsLoading,
    },
  ] = useLazyGetSurveyByIdQuery();
  const [
    getSurveyAdminStatistics,
    {
      data: surveyAdminStatistics,
      isSuccess: isSurveyAdminStatisticsSuccess,
      isFetching: isSurveyAdminStatisticsFetching,
    },
  ] = useLazyGetSurveyAdminStatisticsQuery();

  useEffect(() => {
    if (isSurveysSuccess && surveysData.length > 0) {
      setSelectedSurvey(surveysData[0].id);
      setSelectedSurveyCallback(surveysData[0].id);
    }
  }, [isSurveysSuccess]);

  useEffect(() => {
    if (selectedSurvey) {
      getSurveyById({ surveyId: selectedSurvey });
      getSurveyAdminStatistics(selectedSurvey);
    }
  }, [selectedSurvey]);

  const surveyStatus = useMemo(() => {
    if (!isSelectedSurveySuccess) return null;

    return SurveyStatus.getItemByValue(selectedSurveyData.status);
  }, [isSelectedSurveySuccess]);

  const daysLeft = useMemo(() => {
    if (!isSelectedSurveySuccess) return null;

    const date =
      selectedSurveyData?.softDeadline || selectedSurveyData?.hardDeadline;
    return Math.floor(DateTime.fromISO(date).diff(DateTime.now(), "days").days);
  }, [selectedSurveyData]);

  const chartConfig = {
    xField: "label",
    yField: "count",
    height: 350,
    style: {
      fill: (principle) => principle.color,
    },
    tooltip: {
      items: ["count", "label", "type"],
    },
    scale: {
      x: { padding: 0.4 },
    },
    interaction: {
      tooltip: {
        render: (e, { title, items }) => {
          const entityType = items.find((i) => i.name === "type").value;
          const count = items.find((i) => i.name === "count").value;
          return (
            <div key={title}>
              <h6 style={{ color: token.colorTextThird, fontWeight: "bold" }}>
                {title}
              </h6>
              <h6>
                {count} {entityType}
              </h6>
            </div>
          );
        },
      },
    },
    label: {
      text: () => "",
    },
    axis: {
      x: {
        tick: false,
        grid: false,
        line: { style: { lineWidth: 1 } },
        style: {
          labelFill: "black",
          labelTransform: "scale(1.3) translate(0, 4)",
        },
      },
      y: {
        grid: false,
        tick: false,
        line: { style: { lineWidth: 1 } },
        tickFilter: (tick) => (tick % 2 === 0 || tick === 1 ? tick : null),
        style: {
          labelFill: "black",
          labelTransform: "scale(1.2) translateX(-4)",
        },
      },
    },
    legend: false,
  };

  const objectsGraphConfig = useMemo(() => {
    if (!isSurveyAdminStatisticsSuccess) return {};

    return {
      ...chartConfig,
      data: surveyAdminStatistics?.principles.map((principle) => ({
        label: principle.title,
        count: principle.objectsCount,
        type: t("labels.objects", { plural: "s" }),
        color: principle.color,
      })),
    };
  }, [
    selectedSurvey,
    isSurveyAdminStatisticsFetching,
    isSurveyAdminStatisticsSuccess,
  ]);

  const narrativesGraphConfig = useMemo(() => {
    if (!isSurveyAdminStatisticsSuccess) return {};

    return {
      ...chartConfig,
      data: surveyAdminStatistics?.principles.map((principle) => ({
        label: principle.title,
        count: principle.narrativesCount,
        type: t("labels.narratives", { plural: "s" }),
        color: principle.color,
      })),
    };
  }, [
    selectedSurvey,
    isSurveyAdminStatisticsFetching,
    isSurveyAdminStatisticsSuccess,
  ]);

  return (
    <div className="mb-4">
      {isSurveysSuccess && (
        <Select
          style={{ width: 500 }}
          className="mb-4"
          value={selectedSurvey}
          onChange={(value) => {
            setSelectedSurvey(value);
            setSelectedSurveyCallback(value);
          }}
          options={surveysData.map((survey) => ({
            label: survey.title,
            value: survey.id,
          }))}
        />
      )}
      {isSelectedSurveySuccess && (
        <div>
          <div>
            <div className="mb-1" style={{ display: "flex" }}>
              <Title
                level={5}
                style={{ color: token.colorTextThird }}
                className="me-3"
              >
                {t("reportingPeriod")}
              </Title>
              <div className="me-3">
                <Tag
                  label={surveyStatus.label}
                  color={surveyStatus.background}
                  className="px-3"
                  style={{ color: surveyStatus.color, fontWeight: "bold" }}
                />
              </div>
              {daysLeft !== null && daysLeft >= 0 ? (
                <Text
                  style={{
                    fontWeight: "500",
                    color: "#7E808A",
                    textTransform: "uppercase",
                  }}
                >
                  {daysLeft === 0
                    ? t("lastDayForSurvey")
                    : `${daysLeft} ${t("daysLeft", { plural: daysLeft === 1 ? "" : "s" })}`}
                </Text>
              ) : null}
            </div>
            <div>
              <Title level={5} style={{ color: "#7E808A", fontWeight: 400 }}>
                <span className="me-2">
                  {dayjs(selectedSurveyData.startDate).format(
                    "DD MMM YYYY HH:mm",
                  )}
                </span>
                -
                <span className="ms-2">
                  {dayjs(
                    selectedSurveyData?.softDeadline ||
                      selectedSurveyData.hardDeadline,
                  ).format("DD MMM YYYY HH:mm")}
                </span>
              </Title>
            </div>
          </div>
        </div>
      )}
      {isSurveyAdminStatisticsLoading && (
        <div className="d-flex justify-content-center align-items-center h-100 mt-4">
          <Spin size="large" />
        </div>
      )}
      {isSurveyAdminStatisticsSuccess && (
        <div>
          <div className="mt-4" style={{ display: "flex", flexWrap: "wrap" }}>
            {[
              ...[
                surveyAdminStatistics.audience ===
                SurveyAudience.ORGANIZATION.value
                  ? {
                      label: "organizationsRegisteredInvitedCount",
                      statistic: `${surveyAdminStatistics.surveyInvitesCount}/${surveyAdminStatistics.registeredOrganizationsCount}`,
                      image: "/assets/report-grey.svg",
                    }
                  : {
                      label: "usersRegisteredInvitedCount",
                      statistic: `${surveyAdminStatistics.surveyInvitesCount}/${surveyAdminStatistics.registeredUsersCount}`,
                      image: "/assets/report-grey.svg",
                    },
              ],
              {
                label: "reportsSubmittedDueCount",
                statistic: `${surveyAdminStatistics.submittedDueSurveysCount}/${surveyAdminStatistics.invitedDueSurveysCount}`,
                image: "/assets/report-grey.svg",
              },
              {
                label: "reportsSubmittedNotDueCount",
                statistic: `${surveyAdminStatistics.submittedNotDueSurveysCount}/${surveyAdminStatistics.invitedNotDueSurveysCount}`,
                image: "/assets/report-grey.svg",
              },
              {
                label: "submittedObjectsCount",
                statistic: surveyAdminStatistics.submittedObjectsCount,
                image: "/assets/object-repository-grey.svg",
              },
              {
                label: "submittedNarrativesCount",
                statistic: surveyAdminStatistics.submittedNarrativesCount,
                image: "/assets/narratives-grey.svg",
              },
              {
                label: "overdueSubmittedReportsCount",
                statistic: surveyAdminStatistics.overdueSubmittedSurveysCount,
                image: "/assets/overdue-submitted-reports.svg",
              },
            ].map((i) => (
              <StatisticsBox
                className="me-3 mb-3"
                key={i.label}
                image={i.image}
                label={t(i.label)}
                statistic={i.statistic}
              />
            ))}
          </div>
          <Row className="mt-2">
            <Col sm={24} md={12} lg={12} className="pe-2">
              <div style={{ backgroundColor: "white" }}>
                <Title
                  className="py-3 ps-4"
                  level={4}
                  style={{ fontWeight: 400, color: "#60646C" }}
                >
                  {t("objectsPerPrinciple")}
                </Title>
                <Column {...objectsGraphConfig} />
              </div>
            </Col>
            <Col sm={24} md={12} lg={12} className="ps-2">
              <div style={{ backgroundColor: "white" }}>
                <Title
                  className="py-3 ps-4"
                  level={4}
                  style={{ fontWeight: 400, color: "#60646C" }}
                >
                  {t("narrativesPerPrinciple")}
                </Title>
                <Column {...narrativesGraphConfig} />
              </div>
            </Col>
          </Row>
        </div>
      )}
      <div className="mt-4 py-4 px-5" style={{ backgroundColor: "white" }}>
        <div className="mb-3">
          <Title
            style={{ display: "inline", color: token.colorTextThird }}
            level={4}
          >
            {t("submissionsBy")}
          </Title>
          <Select
            style={{ width: "110px", border: 0, color: "red !important" }}
            className="map-submissions-filter"
            defaultValue="country"
            options={[
              { label: t("labels.country").toLowerCase(), value: "country" },
            ]}
          />
        </div>
        {!isSurveyAdminStatisticsFetching &&
        selectedSurveyData &&
        isSurveyAdminStatisticsSuccess ? (
          <div className="mb-4">
            <div style={{ height: "750px" }}>
              <CountriesMap
                survey={selectedSurveyData}
                countrySurveyStatisticsData={surveyAdminStatistics.countries}
              />
            </div>
            <Row>
              <Col sm={24} md={12} lg={12}>
                <div style={{ width: "70%" }}>
                  <Title
                    style={{ display: "inline", color: token.colorTextThird }}
                    level={4}
                  >
                    {t("submissionsByCountries")}
                  </Title>
                  <div className="mt-2">
                    {surveyAdminStatistics.countries.order
                      .slice(0, 5)
                      .map((country) => (
                        <SubmissionsProgress
                          key={country}
                          countryData={{
                            name: country,
                            ...surveyAdminStatistics.countries.data[country],
                          }}
                        />
                      ))}
                  </div>
                </div>
              </Col>
              <Col sm={24} md={12} lg={12}>
                <div style={{ width: "70%" }}>
                  <Title
                    style={{ display: "inline", color: token.colorTextThird }}
                    level={4}
                  >
                    {t("submissionsByChapters")}
                  </Title>
                  <div className="mt-2">
                    {surveyAdminStatistics.chapters.order
                      .slice(0, 5)
                      .map((country) => (
                        <SubmissionsProgress
                          key={country}
                          countryData={{
                            ...surveyAdminStatistics.chapters.data[country],
                            name: Chapters.getItemByValue(country)?.label,
                          }}
                        />
                      ))}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        ) : null}
      </div>
    </div>
  );
};

ReportingStatistics.propTypes = {
  setSelectedSurveyCallback: PropTypes.func.isRequired,
};

export default ReportingStatistics;
