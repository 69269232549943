import { useEffect } from "react";

import { Spin, theme, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { DownloadOutlined } from "@ant-design/icons";

import Table from "../../../components/Table.jsx";
import { useLazyGetSurveyInvitesQuery } from "../../../redux/service.js";
import Tag from "../../../components/Tag.jsx";
import DownloadSurvey from "../../survey/components/DownloadSurvey.jsx";

const { Title } = Typography;

const Surveys = ({ organizationId }) => {
  const { t } = useTranslation();
  const { token } = theme.useToken();

  const [paginateAndFilterSurveyInvites, surveyInvitesResponse] =
    useLazyGetSurveyInvitesQuery();

  useEffect(() => {
    paginateAndFilterSurveyInvites({
      page: 1,
      pageSize: 10,
      organization: organizationId,
    });
  }, [organizationId]);

  const handleTableChange = (pagination, filter) => {
    paginateAndFilterSurveyInvites({
      page: pagination.current,
      pageSize: pagination.pageSize,
      surveyTitle: filter?.surveyTitle,
      organization: organizationId,
    });
  };

  const columns = [
    {
      title: t("table.title"),
      dataIndex: "survey.title",
      key: "survey.title",
      addSearch: true,
      ellipsis: true,
    },
    {
      title: t("table.status"),
      dataIndex: "status",
      key: "status",
      render: (_, record) => (
        <Tag
          label={
            record.isSubmitted ? t("labels.complete") : t("labels.inProgress")
          }
          color={
            record.isSubmitted
              ? "rgba(2, 186, 60, 0.087)"
              : "rgba(1, 68, 255, 0.059)"
          }
          className="py-1 px-3"
          style={{
            color: record.isSubmitted
              ? "rgba(0, 107, 59, 0.906)"
              : "rgba(0, 37, 158, 0.797)",
            fontWeight: "bold",
          }}
        />
      ),
    },
    {
      title: t("table.submissionDate"),
      dataIndex: "submittedAt",
      key: "submittedAt",
      render: (_, record) =>
        record.submittedAt
          ? DateTime.fromISO(record.submittedAt).toFormat("dd MMM yyyy")
          : "-",
    },
    {
      title: t("table.downloadReport"),
      dataIndex: "downloadReport",
      key: "downloadReport",
      render: (_, record) =>
        record.isSubmitted ? (
          <div style={{ textAlign: "center" }}>
            <DownloadSurvey
              survey={record.survey.title}
              filters={{ organization: organizationId }}
            >
              <DownloadOutlined />
            </DownloadSurvey>
          </div>
        ) : (
          "-"
        ),
    },
  ];

  if (surveyInvitesResponse.isFetching) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div>
      <Title className="mb-3" level={3} style={{ color: token.colorTextThird }}>
        {t("labels.reporting")}
      </Title>
      <Table
        data={surveyInvitesResponse.data?.results || []}
        totalCount={surveyInvitesResponse.data?.count || 0}
        isLoading={
          surveyInvitesResponse.isUninitialized ||
          surveyInvitesResponse.isFetching
        }
        columns={columns}
        handleTableChange={handleTableChange}
      />
    </div>
  );
};

Surveys.propTypes = {
  organizationId: PropTypes.number.isRequired,
};

export default Surveys;
